import React from "react";
import featuresIcon1 from "../../assets/images/features-icon-1.svg";
import featuresIcon2 from "../../assets/images/features-icon-2.svg";
import featuresIcon3 from "../../assets/images/features-icon-3.svg";
import featuresIcon4 from "../../assets/images/features-icon-4.svg";
import "../../assets/styles/home/product.css";

export default function Product() {
  return (
    <section className="section features" id="features">
      <div className="container">
        <div className="features-content">
          <h2 className="h2 section-title">
            Introducing Our Remarkable Product: Spunkhr - EMS
          </h2>

          <p className="section-text">
            Tailored for managers in Staffing, Consulting firms, and Small
            businesses, SpunkHR provides efficient tools to simplify employee
            management.
          </p>

          <ul className="features-list">
            <li className="features-item">
              <img
                src={featuresIcon1}
                width="26"
                height="26"
                loading="lazy"
                aria-hidden="true"
                className="item-icon"
              />

              <h3 className="item-title">Effortless Timesheet Submissions</h3>
            </li>

            <li className="features-item">
              <img
                src={featuresIcon2}
                width="26"
                height="26"
                loading="lazy"
                aria-hidden="true"
                className="item-icon"
              />

              <h3 className="item-title">Enhanced Productivity</h3>
            </li>

            <li className="features-item">
              <img
                src={featuresIcon3}
                width="26"
                height="26"
                loading="lazy"
                aria-hidden="true"
                className="item-icon"
              />

              <h3 className="item-title">Timesheet Tracking</h3>
            </li>

            <li className="features-item">
              <img
                src={featuresIcon4}
                width="26"
                height="26"
                loading="lazy"
                aria-hidden="true"
                className="item-icon"
              />

              <h3 className="item-title">Customizable for You</h3>
            </li>
          </ul>

          <a
            href="https://www.spunkhr.com/"
            className="btn btn-primary"
            target="_blank"
          >
            <span className="span">Discover More</span>
          </a>
        </div>

        <div className="banner-wrapper">
          <figure className="features-banner one">
            <img
              src={require("../../assets/images/about-banner-2.png")}
              width="600"
              height="500"
              loading="lazy"
              alt="features image"
              className="w-100"
            />
          </figure>

          <figure className="features-banner two">
            <img
              src={require("../../assets/images/logo.jpg")}
              width="236"
              height="211"
              loading="lazy"
              alt="features image"
              className="w-100"
            />
          </figure>
        </div>
      </div>
    </section>
  );
}
