import React from "react";
import { Link } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import {
  cardOutline,
  checkmarkDoneOutline,
  globeOutline,
  hourglassOutline,
  layersOutline,
  rocketOutline,
} from "ionicons/icons";
import "../../assets/styles/home/outsourcing.css";

export default function Outsourcing() {
  return (
    <section className="outsource" id="outsource">
      <div className="container">
        <p className="section-subtitle text-center">-Benefits-</p>

        <h2 className="h2 section-title text-center">
          Software Development Outsourcing <br />
          Advantages
        </h2>

        <p className="section-text text-center">
          In the dynamic business world, firms strive for competitiveness and
          innovation. Software development outsourcing is a prominent strategy,
          involving delegating tasks to external experts.
        </p>

        <ul className="outsource-list">
          <li>
            <div className="outsource-card">
              <div className="card-icon">
                <IonIcon icon={cardOutline} aria-hidden="true" />
              </div>

              <div className="card-content">
                <h3 className="h4 card-title">Cost Efficiency</h3>

                <p className="section-text">
                  Significantly reduce operational costs. By leveraging the
                  expertise of offshore or nearshore teams, companies can avoid
                  hefty investments in hiring and training in-house talent,
                  purchasing hardware and software, and maintaining
                  infrastructure.
                </p>
              </div>
            </div>
          </li>

          <li>
            <div className="outsource-card">
              <div className="card-icon">
                <IonIcon icon={hourglassOutline} aria-hidden="true" />
              </div>

              <div className="card-content">
                <h3 className="h4 card-title">Faster Time-to-Market</h3>

                <p className="section-text">
                  In the competitive world of technology, time-to-market is of
                  utmost importance. Outsourcing accelerates the development
                  process by tapping into experienced teams that can quickly
                  bring products from concept to deployment.
                </p>
              </div>
            </div>
          </li>

          <li>
            <div className="outsource-card">
              <div className="card-icon">
                <IonIcon icon={globeOutline} aria-hidden="true" />
              </div>

              <div className="card-content">
                <h3 className="h4 card-title">Access to Specialized Skills</h3>

                <p className="section-text">
                  The IT landscape is vast and dynamic, making it challenging
                  for any single team to possess expertise in all domains.
                  Outsourcing allows businesses to collaborate with teams that
                  specialize in specific technologies, programming languages, or
                  industry verticals.
                </p>
              </div>
            </div>
          </li>

          <li>
            <div className="outsource-card">
              <div className="card-icon">
                <IonIcon icon={layersOutline} aria-hidden="true" />
              </div>

              <div className="card-content">
                <h3 className="h4 card-title">Scalability and Flexibility</h3>

                <p className="section-text">
                  Software development projects often experience fluctuations in
                  resource requirements. Outsourcing partners provide the
                  flexibility to scale up or down as needed, ensuring optimal
                  resource allocation throughout the project lifecycle.
                </p>
              </div>
            </div>
          </li>

          <li>
            <div className="outsource-card">
              <div className="card-icon">
                <IonIcon icon={checkmarkDoneOutline} aria-hidden="true" />
              </div>

              <div className="card-content">
                <h3 className="h4 card-title">Risk Mitigation</h3>

                <p className="section-text">
                  Collaborating with a reputable outsourcing partner can
                  mitigate risks associated with project delays, budget
                  overruns, and unforeseen technical challenges.
                </p>
              </div>
            </div>
          </li>

          <li>
            <div className="outsource-card">
              <div className="card-icon">
                <IonIcon icon={rocketOutline} aria-hidden="true" />
              </div>

              <div className="card-content">
                <h3 className="h4 card-title">Focus on Core Competencies</h3>

                <p className="section-text">
                  Outsourcing non-core activities like software development
                  enables businesses to channel their internal resources and
                  energy toward core competencies.
                </p>
              </div>
            </div>
          </li>
        </ul>

        <Link to="/outsource" className="btn btn-primary">
          View all benefits
        </Link>
      </div>
    </section>
  );
}
