import React from "react";
import { Link } from "react-router-dom";

export default function Inclusion() {
  return (
    <div className="grid-list swap-grid">
      <figure className="about-banner item1">
        <img
          src={require("../../assets/images/about-banner.png")}
          width="802"
          height="654"
          loading="lazy"
          alt="about banner"
          className="w-100"
        />
      </figure>
      <div className="about-content item2">
        <h2 className="h2-sm section-title">
          Inclusion is the bedrock of our philosophy
        </h2>

        <p className="section-text">
          We understand that diversity without genuine inclusion falls short of
          its potential. We're dedicated to providing a safe and respectful
          platform where everyone's voice is heard and valued. Our commitment to
          inclusivity ensures that each individual's uniqueness is not only
          acknowledged but celebrated.
        </p>

        <ul className="about-list">
          <li className="has-before">
            Every voice matters, creating a sense of community.
          </li>

          <li className="has-before">
            Inclusion fosters camaraderie and support.
          </li>

          <li className="has-before">
            {" "}
            Individuals contribute their unique strengths.
          </li>
        </ul>

        <div className="btn-group">
          <Link to="/diversity#" className="btn btn-primary">
            Know More
          </Link>
        </div>
      </div>
    </div>
  );
}
