import React from "react";
import Hero from "./Hero";
import Services from "./Services";
import AboutUs from "./AboutUs";
import CTA from "./CTA";
import Outsourcing from "./Outsourcing";
import Consulting from "./Consulting";
import Product from "./Product";

export default function Main() {
  return (
    <main>
      <article>
        <Hero />
        <Outsourcing />
        <Consulting />
        <Product />
        <AboutUs />
        <CTA />
        <Services />
      </article>
    </main>
  );
}
