import React from "react";
import useGoogleAnalytics from "../lib/useGoogleAnalytics";
import DiversityContent from "../components/articles/Diversity";
import PageHeaderContainer from "../components/layout/PageHeaderContainer";

const data = {
  title: "Diversity",
  text: `At SpunkIT LLC, diversity isn't just a word—it's our foundation for success.
  As a leading IT company, we know diverse teams drive innovation. Our varied perspectives
  spark fresh ideas and inventive solutions, shaping the tech industry.`,
  subText: "Diversity & Inclusion",
};

export default function Diversity() {
  useGoogleAnalytics();
  return (
    <>
      <PageHeaderContainer data={data} />
      <DiversityContent />
    </>
  );
}
