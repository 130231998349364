import React from "react";
import { IonIcon } from "@ionic/react";
import { play } from "ionicons/icons";
import "../../assets/styles/home/hero.css";

export default function Hero() {
  return (
    <section className="section hero" id="home" aria-label="hero">
      <div className="container">
        <div className="hero-content">
          <p className="hero-subtitle has-before">Welcome to Our Agency</p>

          <h1 className="h1 hero-title">
            Crafting project specific solutions with expertise
          </h1>

          <p className="hero-text">
            Step into the world of SpunkIT LLC – a proudly certified
            Veteran-Owned Small Business where expertise and integrity converge
            to create a symphony of outstanding results that truly leave an
            impression. Your journey with us is bound to be remarkable.
          </p>

          <div className="btn-group">
            <a href="#outsource" className="btn btn-primary">
              Discover More
            </a>

            <button className="flex-btn">
              <div className="btn-icon">
                <IonIcon icon={play} aria-hidden="true" />
              </div>

              <span className="span">How it works</span>
            </button>
          </div>
        </div>

        <figure
          className="hero-banner has-before img-holder"
          style={{ "--width": 650, "--height": 650 }}
        >
          <img
            src={require("../../assets/images/hero-banner.png")}
            width="650"
            height="650"
            alt="hero banner"
            className="img-cover"
          />
          <img
            src={require("../../assets/images/veteran-owned-certified.png")}
            width="800"
            height="717"
            loading="lazy"
            alt="Ethan's Photo"
            className="abs-img move-anim"
            style={{ borderRadius: "unset" }}
          ></img>
        </figure>
      </div>
    </section>
  );
}
