import React from "react";
import useGoogleAnalytics from "../lib/useGoogleAnalytics";
import UnderConstruction from "../components/layout/UnderConstruction";
import PageHeaderContainer from "../components/layout/PageHeaderContainer";
import OutsourcingContent from "../components/articles/Outsourcing";
import CTA from "../components/home/CTA";

const data = {
  title: "Outsourcing",
  text: `In the dynamic business world, firms strive for competitiveness
              and innovation. Software development outsourcing is a prominent
              strategy, involving delegating tasks to external experts. This
              harnesses specialized skills and resources, offering strategic
              benefits for companies in the digital era.`,
  subText: "Benefits in outsourcing",
};

export default function Outsource() {
  useGoogleAnalytics();
  return (
    <>
      <PageHeaderContainer data={data} />
      <OutsourcingContent />
      <CTA />
    </>
  );
}
