import React from "react";
import "../../assets/styles/layout/pageheaderContainer.css";

export default function PageHeaderContainer({ data }) {
  return (
    <>
      <section
        className="section pageheader has-bg-image"
        aria-labelledby="pageheader-label"
        style={{
          backgroundImage: `url(${require("../../assets/images/pageheader-bg.png")})`,
        }}
      >
        <div className="container">
          <div className="title-wrapper">
            <div>
              <p className="section-subtitle">-{data.subText}-</p>

              <h1 className="h1 hero-title">{data.title}</h1>
            </div>

            <p className="section-text">{data.text}</p>
          </div>
        </div>

        <img
          src={require("../../assets/images/hero-shape.png")}
          width="116"
          height="116"
          loading="lazy"
          className="hero-shape shape-1"
        />
      </section>
    </>
  );
}
