import React from "react";

export default function UnderConstruction({ page }) {
  return (
    <section className="section hero" id="home" aria-label="hero">
      <div className="container">
        <div>
          <p className="section-subtitle title-lg has-after" id="about-label">
            {page}
          </p>
          <h1 className="h1 hero-title">
            Building Something Amazing - Stay Tuned!
          </h1>
        </div>
        <figure className="" style={{ "--width": 650, "--height": 650 }}>
          <img
            src={require("../../assets/images/under-C.png")}
            width="650"
            height="650"
            alt="hero banner"
            className="img-cover"
          />
        </figure>
      </div>
    </section>
  );
}
