import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";
import Home from "./pages/Home";
import AboutPage from "./pages/About";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Diversity from "./pages/Diversity";
import Outsource from "./pages/Outsource";
import Consulting from "./pages/Consulting";

// OUR_TRACKING_ID
const TRACKING_ID = "G-F5MJWC4995";
ReactGA.initialize(TRACKING_ID);

export default function App() {
  return (
    <>
      <Header
        links={["Home", "About Us", "Outsource", "Consulting", "Diversity"]}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/diversity" element={<Diversity />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/consulting" element={<Consulting />} />
        <Route path="/outsource" element={<Outsource />} />
      </Routes>
      <Footer />
    </>
  );
}
