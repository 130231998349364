import React from "react";
import "../../assets/styles/about/about.css";
import data from "./data";

export default function About() {
  return (
    <section className="section features" id="features" aria-label="features">
      <div className="container">
        <p className="section-subtitle text-center">-Why Choose Us-</p>

        <h2 className="h2 section-title text-center">
          SpunkIT LLC- An Innovative Solution <br />
          to your Needs
        </h2>

        <p className="section-text text-center">
          Welcome to SpunkIT LLC, your partner in Information Technology
          excellence. As a dynamic Software Development and Consulting Company,
          we are dedicated to shaping the future through innovation and
          technological prowess.
        </p>

        <ul className="grid-list">
          {data.map(({ num, title, text }) => (
            <li style={{ marginInline: "auto" }}>
              <div className="features-card">
                <data className="card-number" value={`0${num}`}>
                  0{num}
                </data>

                <h3 className="h3 card-title">{title}</h3>

                <p className="card-text">{text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
