import React from "react";
import "../../assets/styles/home/consulting.css";
import { Link } from "react-router-dom";

export default function Consulting() {
  return (
    <section
      className="section consulting"
      id="consulting"
      aria-label="consulting"
    >
      <div className="container">
        <figure className="consulting-banner">
          <img
            src={require("../../assets/images/con-banner1.png")}
            width="450"
            height="590"
            loading="lazy"
            alt="consulting banner"
            className="w-100 consulting-img"
          />

          <img
            src={require("../../assets/images/con-banner2.jfif")}
            width="188"
            height="242"
            loading="lazy"
            aria-hidden="true"
            alt="consulting banner"
            className="abs-img abs-img-1"
          />
        </figure>

        <div className="consulting-content">
          <p className="section-subtitle">Elevate Your Expertise</p>

          <h2 className="h2 section-title">Consulting & Training program</h2>

          <ul className="consulting-list">
            <li className="consulting-item">
              <div className="item-icon item-icon-1">
                <img
                  src={require("../../assets/images/consulting-icon-1.png")}
                  width="30"
                  height="30"
                  loading="lazy"
                  aria-hidden="true"
                  alt="consulting icon"
                />
              </div>

              <div>
                <h3 className="h3 item-title">
                  Hands-On Ongoing Project Training
                </h3>

                <p className="item-text">
                  Theory is essential, but practical experience is invaluable.
                  Our Hands-On Ongoing Project Training immerses you in
                  real-world projects.
                </p>
              </div>
            </li>

            <li className="consulting-item">
              <div className="item-icon item-icon-2">
                <img
                  src={require("../../assets/images/consulting-icon-3.png")}
                  width="30"
                  height="30"
                  loading="lazy"
                  aria-hidden="true"
                  alt="consulting icon"
                />
              </div>

              <div>
                <h3 className="h3 item-title">Experts for Your Needs</h3>

                <p className="item-text">
                  Staying connected and informed is key to staying competitive.
                  Our Daily Call Sessions keep you engaged with the latest
                  industry trends, emerging technologies, and best practices.
                </p>
              </div>
            </li>

            <li className="consulting-item">
              <div className="item-icon item-icon-3">
                <img
                  src={require("../../assets/images/consulting-icon-2.png")}
                  width="30"
                  height="30"
                  loading="lazy"
                  aria-hidden="true"
                  alt="consulting icon"
                />
              </div>

              <div>
                <h3 className="h3 item-title">Biggest Student Community</h3>

                <p className="item-text">
                  Every individual's learning journey is unique. That's why we
                  provide personalized guidance through our team of seasoned
                  professionals.
                </p>
              </div>
            </li>
          </ul>

          <Link to="/consulting" className="btn btn-primary">
            <span className="span">Explore More</span>
          </Link>
        </div>
      </div>
    </section>
  );
}
