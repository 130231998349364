import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { menuOutline, closeOutline } from "ionicons/icons";
import "../../assets/styles/layout/header.css";

export default function Header({ links }) {
  const [active, setActive] = useState(false);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const resolveHeader = () => {
    if (offset > 100) return "active";
    else if (active) return "nav-active";
    else return "";
  };

  return (
    <header className={`header ${resolveHeader()}`}>
      <div className="container">
        <a href="#" className="logo">
          <img
            src={require("../../assets/images/spunklogo.png")}
            width="148"
            height="48"
            alt="SpunkIT logo"
          />
        </a>

        <button
          className={`nav-toggle-btn ${active ? "active" : ""}`}
          aria-label="Toggle Menu"
          data-nav-toggle-btn
          onClick={() => setActive(!active)}
        >
          <IonIcon icon={menuOutline} className="menu-icon" />
          <IonIcon icon={closeOutline} className="close-icon" />
        </button>

        <nav className={`navbar  ${active ? "active" : ""}`}>
          <ul className="navbar-list">
            {links.map((link) => (
              <li key={link}>
                <NavLink
                  to={`${
                    link === "Home" ? "/" : link.toLowerCase().split(" ")[0]
                  }`}
                  onClick={() => active && setActive(!active)}
                  className="navbar-link"
                  data-nav-link
                >
                  {link}
                </NavLink>
              </li>
            ))}
            <li>
              <a href="#" className="btn btn-primary">
                Get Started
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
