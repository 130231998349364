import React from "react";
import { Link } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import {
  call,
  location,
  logoFacebook,
  logoInstagram,
  logoPinterest,
  logoTwitter,
  mail,
  paperPlane,
} from "ionicons/icons";
import "../../assets/styles/layout/footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-top section">
        <div className="container">
          <div className="footer-brand">
            <a href="#" className="logo">
              <img
                src={require("../../assets/images/spunklogo.png")}
                width="148"
                height="48"
                alt="SpunkIT logo"
              />
            </a>

            <p className="footer-text">
              Complete business solutions with Advanced technologies and highly
              determined team.
            </p>

            <form action="" className="newsletter-form">
              <input
                type="email"
                name="email_address"
                placeholder="Enter your email"
                required
                className="email-field"
              />

              <button type="submit" className="form-btn">
                <IonIcon icon={paperPlane} className="menu-icon" />
              </button>
            </form>
          </div>

          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Our Services</p>
            </li>

            {["Content", "Community", "Events", "Resources"].map((link) => (
              <li key={link}>
                <a href="#" className="footer-link">
                  {link}
                </a>
              </li>
            ))}
          </ul>

          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Company</p>
            </li>

            {[
              ["About Company", "/about"],
              ["Outsourcing", "/outsource"],
              ["Consulting & Training", "/consulting"],
              ["Diversity", "/diversity"],
              ["Contact Us", "/"],
            ].map((link) => (
              <li key={link}>
                <Link to={link[1]} className="footer-link">
                  {link[0]}
                </Link>
              </li>
            ))}
          </ul>

          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Contact Us</p>
            </li>

            <li className="footer-item">
              <IonIcon icon={location} className="menu-icon" />

              <address className="contact-link address">Dallas, TX</address>
            </li>

            <li className="footer-item">
              <IonIcon icon={call} className="menu-icon" />

              <a href="tel:+7894631546876" className="contact-link">
                + 4698609427
              </a>
            </li>

            <li className="footer-item">
              <IonIcon icon={mail} className="menu-icon" />

              <a href="mailto:info@hoolix.com" className="contact-link">
                info@spunkitllc.com
              </a>
            </li>

            <li className="footer-item">
              <ul className="social-list">
                <li>
                  <a href="#" className="social-link">
                    <IonIcon icon={logoFacebook} className="menu-icon" />
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    <IonIcon icon={logoTwitter} className="menu-icon" />
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    <IonIcon icon={logoInstagram} className="menu-icon" />
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    <IonIcon icon={logoPinterest} className="menu-icon" />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <p className="copyright">&copy; 2023 SpunkIT | All Rights Reserved</p>

          <ul className="footer-bottom-list">
            <li>
              <a href="#" className="footer-bottom-link">
                Privacy Policy
              </a>
            </li>

            <li>
              <a href="#" className="footer-bottom-link">
                Terms of Use
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
