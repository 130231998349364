const data = [
  {
    num: "1",
    title: "Our Vision",
    text: "Our vision and Mission drives us to redefine the IT landscape, setting new standards and pushing boundaries.",
  },
  {
    num: "2",
    title: "IT and Consulting Experts",
    text: "With vast experience, we tackle challenges head-on, providing insights for transformative solutions.",
  },
  {
    num: "3",
    title: "Community Involvement",
    text: "Beyond business, we engage in IT community initiatives and events. Our commitment extends to contributing to the growth of the IT ecosystem.",
  },
  {
    num: "4",
    title: "Our Role",
    text: "SpunkIT LLC is a pioneering Consulting and Software Development company. We convert ideas into reality, delivering tailored strategies and robust software solutions.",
  },
  {
    num: "5",
    text: "Our culture thrives on creativity, collaboration, and innovation. Team members contribute their unique skills to ensure we consistently exceed expectations.",
    title: "Company Culture",
  },

  {
    num: "6",
    title: "Client-Centric Approach",
    text: "At SpunkIT LLC, clients are our core. Our customized approach recognizes unique business needs, delivering exceptional value and success.",
  },

  {
    num: "7",
    title: "Future Goals",
    text: "Our goals drive us forward. Expanding reach, fostering innovation, and establishing industry leadership define our path.",
  },
];

export default data;
