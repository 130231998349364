import React from "react";
import "../../assets/styles/articles/consulting.css";
import { consulting } from "./artices";
import { getImage } from "./ArticleRendering";

export default function ConsultingContent() {
  return (
    <section className="section article" aria-label="our article">
      <div className="container">
        <p className="section-subtitle text-center">-Benefits-</p>

        <h2 className="h2 section-title text-center">
          Elevate Your Expertise with SpunkIT LLC's <br /> Comprehensive
          Training Services
        </h2>

        <p className="section-text text-center">{consulting.text}</p>
        <ul>
          {consulting.content.map((c) => (
            <li className="article-list">
              <div className="article-wrapper">
                <div>
                  <p className="section-subtitle">{c.subTitle}</p>
                  <h2 className="h2 section-title">{c.title}</h2>
                  {c.text.map((t) => (
                    <p className="section-text">{t}</p>
                  ))}
                </div>

                <figure className="article-banner">{getImage(c.photo)}</figure>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
