import React from "react";
import useGoogleAnalytics from "../lib/useGoogleAnalytics";
import PageHeaderContainer from "../components/layout/PageHeaderContainer";
import ConsultingContent from "../components/articles/Consulting";

const data = {
  title: "Consulting",
  text: `Our commitment to your success extends beyond consultations, as we recognize the
  importance of equipping your team with the skills they need to excel. Through our
  tailored training programs, we empower your workforce to harness newfound knowledge
  and capabilities, ensuring that the strategies and recommendations put forth by our
  consultants are not only understood but executed with precision.`,
  subText: "Elevate Your Expertise",
};

export default function Consulting() {
  useGoogleAnalytics();
  return (
    <>
      <PageHeaderContainer data={data} />
      <ConsultingContent />
    </>
  );
}
