import React, { useState } from "react";
import { History, Mission, WhoWeAre } from "./ObjectiveContents";

export default function Objectives() {
  const [active, setActive] = useState(0);
  return (
    <div className="grid-list swap-grid">
      <figure className="about-banner item1" data-reveal="right">
        <img
          src={require("../../assets/images/about-banner.png")}
          width="554"
          height="678"
          loading="lazy"
          alt="about banner"
          className="w-100"
        />
      </figure>
      <div className="about-content item2">
        <p className="section-subtitle title-lg has-after" id="about-label">
          About Us
        </p>

        <h2 className="h2-sm section-title">Experienced Workers</h2>

        <p className="section-text">
          Our commitment to fostering an inclusive environment is at the core of
          everything we do. We're more than a website; we're a community that
          celebrates the rich tapestry of humanity in all its forms.
        </p>

        <ul className="tab-list">
          {["Our History", "Our Mission", "Who we are"].map((obj, i) => (
            <li key={obj}>
              <button
                className={`tab-btn ${active === i ? "active" : ""}`}
                onClick={() => setActive(i)}
              >
                {obj}
              </button>
            </li>
          ))}
        </ul>

        {active === 0 && <History />}
        {active === 1 && <Mission />}
        {active === 2 && <WhoWeAre />}
      </div>
    </div>
  );
}
