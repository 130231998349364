import React from "react";
import { IonIcon } from "@ionic/react";
import { arrowForwardOutline, chevronForward } from "ionicons/icons";
import "../../assets/styles/home/cta.css";

export default function CTA() {
  return (
    <section
      className="section cta"
      aria-label="workshop"
      style={{
        backgroundImage: `url(${require("../../assets/images/cta-bg.png")})`,
      }}
    >
      <div className="container">
        <figure className="cta-banner">
          <img
            src={require("../../assets/images/d.jpg")}
            width="580"
            height="380"
            loading="lazy"
            alt="cta banner"
            className="img-cover"
          />
        </figure>

        <div className="cta-content">
          <p className="section-subtitle">So What is Next?</p>

          <h2 className="h2 section-title">Join Us in Making a Difference</h2>

          <p className="section-text">
            Ready to embark on a transformative experience? Join us at SpunkIT
            and be a champion of diversity and inclusion. Together, we can shape
            a brighter, more harmonious future for all.
          </p>

          <a href="#" className="btn btn-secondary">
            <span className="span">Get Started</span>
          </a>
        </div>
      </div>
    </section>
  );
}
