const outsource = [
  {
    title: "Cost Efficiency",
    content: [
      `Outsourcing software development can significantly reduce operational costs. 
      By leveraging the expertise of offshore or nearshore teams,
      companies can avoid hefty investments in hiring and training in-house talent, 
      purchasing hardware and software, and maintaining infrastructure.`,
      `This cost-effective approach frees up financial resources that can be
      redirected toward core business activities and expansion.`,
    ],
    photo: "outsource-banner1.jpg",
  },
  {
    title: "Access to Specialized Skills",
    content: [
      `The IT landscape is vast and dynamic, making it challenging for any single
      team to possess expertise in all domains. Outsourcing allows businessesto
      collaborate with teams that specialize in specific technologies, programming
      languages, or industry verticals.`,
      `This ensures that projects are executed with precision and cutting-edge solutions,
      enhancing the overall quality of software products.`,
    ],
    photo: "outsource-banner2.jpg",
  },
  {
    title: "Faster Time-to-Market",
    content: [
      `In the competitive world of technology, time-to-market is of utmost importance.
      Outsourcing accelerates the development process by tapping into experienced teams
      that can quickly bring products from concept to deployment.`,
      `This agility is crucial for capitalizing on emerging trends and gaining a competitive edge.`,
    ],
    photo: "outsource-banner3.jpg",
  },
  {
    title: "Focus on Core Competencies",
    content: [
      `Outsourcing non-core activities like software development enables businesses to
      channel their internal resources and energy toward core competencies.`,
      `This strategic shift allows companies to concentrate on innovation, business strategy,
      and customer engagement, while leaving technical aspects to dedicated experts.`,
    ],
    photo: "outsource-banner4.jpg",
  },
  {
    title: "Scalability and Flexibility",
    content: [
      `Software development projects often experience fluctuations in resource requirements. 
      Outsourcing partners provide the flexibility to scale up or down as needed, ensuring optimal 
      resource allocation throughout the project lifecycle.`,
      `This eliminates the need to hire and train new employees for short-term projects.`,
    ],
    photo: "outsource-banner5.jpg",
  },
  {
    title: "Risk Mitigation",
    content: [
      `Collaborating with a reputable outsourcing partner can mitigate risks associated with project delays,
      budget overruns, and unforeseen technical challenges.`,
      `Established outsourcing providers adhere to industry best practices and 
      have experience managing various types of software projects.`,
    ],
    photo: "outsource-banner6.jpg",
  },
  {
    title: "Global Perspective and Innovation",
    content: [
      `Outsourcing brings in fresh perspectives and innovative ideas from diverse global teams.`,
      `This infusion of creativity can lead to groundbreaking solutions that may not have been 
      possible within the confines of a single organizational culture.`,
    ],
    photo: "outsource-banner7.png",
  },
  {
    title: "24/7 Development Cycle",
    content: [
      `Working with offshore teams in different time zones enables a seamless round-the-clock development cycle.`,
      `This maximizes productivity by ensuring that work progresses even when your in-house team is not working.`,
    ],
    photo: "outsource-banner8.jpg",
  },
];

const consulting = {
  text: `In the ever-evolving world of business and technology, staying ahead requires more than just insights
      and recommendations. At SpunkIT LLC, we understand that true success comes from a combination of expert guidance and
      continuous learning. That's why our commitment to your growth goes beyond traditional consulting services – we offer a
      range of tailored training solutions designed to enhance your consulting and propel your career forward.`,
  content: [
    {
      title: "Hands-On Ongoing Project Training",
      text: [
        `Theory is essential, but practical experience is invaluable. Our Hands-On Ongoing Project Training immerses you in real-world projects, enabling you to apply theoretical concepts in a tangible setting.`,
        `Guided by seasoned professionals, you'll tackle industry-relevant challenges, collaborate with a diverse team, and develop the problem-solving skills crucial for success in today's dynamic landscape.`,
      ],
      subTitle: "Learn by Doing",
      photo: "consulting-banner1.png",
    },
    {
      title: "Daily Call Sessions",
      text: [
        `Staying connected and informed is key to staying competitive. Our Daily Call Sessions keep you engaged with the latest industry trends, emerging technologies, and best practices.`,
        `Led by subject matter experts, these sessions offer bite-sized insights, fostering a culture of continuous learning that keeps you at the forefront of your field.`,
      ],
      subTitle: "Stay Informed and Engaged",
      photo: "consulting-banner2.png",
    },
    {
      title: "Experts for Your Needs",
      text: [
        `Every individual's learning journey is unique. That's why we provide personalized guidance through our team of seasoned professionals.`,
        `Whether you're seeking career advice, technical insights, or strategic direction, our experts are available to provide one-on-one
        consultations that cater to your specific needs, ensuring you're equipped to overcome challenges and seize opportunities.`,
      ],
      subTitle: "Personalized Guidance",
      photo: "consulting-banner3.png",
    },
  ],
};

const diversity = [
  {
    title: "Embracing Diversity for Innovation",
    content: [
      `Tech thrives on innovation, and at SpunkIT, diversity fuels it. Our team members from different backgrounds,
    genders, and cultures spark creative collisions.`,
      ` Collaborating, we shatter norms, embracing new ideas and breakthrough solutions.`,
    ],
    photo: "diversity-banner1.png",
  },
  {
    title: "Inclusion: Where Every Voice Matters",
    content: [
      `Diversity alone isn't enough; inclusion is our power move. At SpunkIT,
      we ensure everyone feels valued and heard.`,
      `Inclusion ignites our success—happy teams, high morale, and unmatched outcomes.`,
    ],
    photo: "diversity-banner2.png",
  },
  {
    title: "Our Diverse Tapestry",
    content: [
      "Our team is a vibrant tapestry woven by individuals from all walks of life. Coders, designers, managers, and analysts unite in purpose.",
      "Our employee resource groups foster a supportive community.",
    ],
    photo: "diversity-banner3.png",
  },
  {
    title: "Driving Change, Inside and Out",
    content: [
      "We champion change within and beyond. Mentorship, workshops, collaborations—we reshape tech's landscape.",
      "Our diversity transforms us and the industry.",
    ],
    photo: "diversity-banner4.png",
  },
  {
    title: "A Future Built on Diversity",
    content: [
      "Tomorrow, we're dedicated to even more diversity. At every level, we nurture tech leaders, no matter their background.",
      "Diversity isn't an option; it's our evolution.",
    ],
    photo: "diversity-banner5.png",
  },
  {
    title: "Join the Movement",
    content: [
      "You're invited. As a potential teammate, partner, or tech advocate, your voice matters.",
      "Join SpunkIT LLC—where rewriting the rules is our game",
    ],
    photo: "diversity-banner6.png",
  },
];

export { outsource, consulting, diversity };
