import React from "react";
import useGoogleAnalytics from "../lib/useGoogleAnalytics";
import PageHeaderContainer from "../components/layout/PageHeaderContainer";
import About from "../components/about/about";

const data = {
  title: "About Us",
  text: `Our commitment to fostering an inclusive environment is at the core of everything we do.
  We're more than a website; we're a community that celebrates the rich tapestry of humanity in all its forms.`,
  subText: "Elevate Your Expertise",
};

export default function AboutPage() {
  useGoogleAnalytics();
  return (
    <>
      <PageHeaderContainer data={data} />
      <About />
    </>
  );
}
