import React from "react";
import { Link } from "react-router-dom";

export default function Diversity() {
  return (
    <div className="grid-list">
      <figure className="about-banner">
        <img
          src={require("../../assets/images/about-banner.png")}
          width="802"
          height="654"
          loading="lazy"
          alt="about banner"
          className="w-100"
        />
      </figure>

      <div className="about-content">
        <h2 className="h2-sm section-title">
          Diversity is more than just a checkbox
        </h2>

        <p className="section-text">
          It's about embracing a wide spectrum of backgrounds, experiences,
          perspectives, and talents. By welcoming people from different walks of
          life, we create a dynamic and vibrant space where fresh ideas
          flourish, and creativity knows no bounds.
        </p>

        <ul className="about-list">
          <li className="has-before">
            Differing backgrounds spark fresh ideas and inventive solutions.
          </li>

          <li className="has-before">
            Exposure to varied backgrounds cultivates continuous growth.
          </li>

          <li className="has-before">
            Fosters empathy, respect, and bridges between communities.
          </li>
        </ul>

        <div className="btn-group">
          <Link to="/diversity" className="btn btn-primary">
            Know More
          </Link>
        </div>
      </div>
    </div>
  );
}
