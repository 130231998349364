import React from "react";
import ArticleRendering from "./ArticleRendering";
import { diversity } from "./artices";

export default function DiversityContent() {
  return (
    <section className="section about outsource" id="about" aria-label="about">
      <div className="container">
        <p className="section-subtitle text-center">-Diverse Tapestry-</p>

        <h2 className="h2 section-title text-center">
          Diversity at SpunkIT LLC <br />
          Celebrating Differences, Building Excellence
        </h2>
        <ul>
          <ArticleRendering article={diversity} />
        </ul>
      </div>
    </section>
  );
}
