import React from "react";

const getImage = (image, title) => {
  return (
    <img
      src={require(`../../assets/images/${image}`)}
      alt={title}
      loading="lazy"
      className="w-100"
    />
  );
};

export default function ArticleRendering({ article }) {
  return (
    <>
      {" "}
      {article.map(({ title, content, photo }, i) => (
        <li className={`grid-list ${i % 2 !== 0 && "swap-grid"}`}>
          <figure
            className={`about-banner ${i % 2 !== 0 && "item1"}`}
            key={photo}
          >
            {getImage(photo, title)}
          </figure>

          <div className={`about-banner ${i % 2 !== 0 && "item2"}`} key={title}>
            <h2 className="h2-sm section-title">{title}</h2>

            {content.map((c, i) => {
              return (
                <p className="section-text" key={i}>
                  {c}
                </p>
              );
            })}
          </div>
        </li>
      ))}
    </>
  );
}

export { getImage };
