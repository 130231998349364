import React from "react";
import { Link, NavLink } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { arrowForwardOutline } from "ionicons/icons";
import "../../assets/styles/home/services.css";

const data = [
  {
    title: "Content",
    text: `Explore a plethora of articles, stories, and resources that
                  highlight the importance of diversity and inclusion in various
                  aspects of life – from business and education to culture and
                  society.`,
    icon: "service-1.png",
  },
  {
    title: "Community",
    text: `Join our diverse community of members who share their personal
                  experiences, insights, and wisdom. Engage in conversations
                  that broaden your horizons and challenge your perspectives.`,
    icon: "service-2.png",
  },
  {
    title: "Events",
    text: `Stay tuned for exciting events and webinars featuring thought
                  leaders, activists, and experts who are at the forefront of
                  the diversity and inclusion movement.`,
    icon: "service-3.png",
  },
  {
    title: "Resources",
    text: `Access a wealth of resources, including reading lists, guides,
                  and toolkits, to help you understand, advocate for, and
                  integrate diversity and inclusion principles into your own
                  life and work.`,
    icon: "service-4.png",
  },
];

const getImage = (image, title) => {
  return (
    <img
      src={require(`../../assets/images/${image}`)}
      width="140"
      height="140"
      loading="lazy"
      alt={title}
      className="img"
    />
  );
};

export default function Services() {
  return (
    <section className="section service" id="services" aria-label="service">
      <div className="container">
        <p className="section-subtitle text-center">-What We Offer-</p>

        <h2 className="h2 section-title text-center">Our Creative Services</h2>

        <p className="section-text text-center">
          Get the most of reduction in your team’s operating costs for the whole
          product which creates amazing UI/UX experiences.
        </p>

        <ul className="grid-list">
          {data.map(({ title, text, icon }) => (
            <li key={title}>
              <div className="service-card has-after">
                <figure className="card-icon">{getImage(icon, title)}</figure>

                <div className="card-content">
                  <h3 className="h3 card-title">{title}</h3>

                  <p className="card-text">{text}</p>

                  <NavLink to="/services" className="btn-link">
                    <span className="span">Read More</span>

                    <IonIcon icon={arrowForwardOutline} aria-hidden="true" />
                  </NavLink>
                </div>
              </div>
            </li>
          ))}
        </ul>

        <Link to="/services" className="btn btn-primary">
          Get Started
        </Link>
      </div>
    </section>
  );
}
