import React from "react";
import Diversity from "./Diversity";
import Objectives from "./Objective";
import "../../assets/styles/home/about.css";
import Inclusion from "./Inclusion";

export default function AboutUs() {
  return (
    <section className="section about" id="about" aria-label="about">
      <div className="container">
        <p className="section-subtitle text-center">-About Us-</p>

        <h2 className="h2 section-title text-center">
          Where Diversity and Inclusion <br />
          Shape Excellence!
        </h2>

        <p className="section-text text-center">
          At SpunkIT, we wholeheartedly believe that diversity and Inclusion is
          not just a buzzword – it's the very essence of progress, innovation,
          and strength.
        </p>

        <Objectives />
        <Diversity />
        <Inclusion />
      </div>
    </section>
  );
}
