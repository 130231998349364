import React from "react";
import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

const whoWeAreList = [
  "SpunkIT LLC: Diverse community hub.",
  "Hosts thought-provoking events.",
];

const missionList = [
  "Empower through diversity resources.",
  "Respectful platform for every voice.",
  "Celebrate understanding and uniqueness.",
];

const historyList = [
  "Founded on diverse progress and strength.",
  "Committed to building inclusivity.",
  "Embraces the value of diverse perspectives.",
  "Shapes a future united by differences.",
];

export function Mission() {
  return (
    <>
      {" "}
      <p className="tab-text">
        At SpunkIT LLC, clients are our core. Our customized approach recognizes
        unique business needs, delivering exceptional value and success.
      </p>
      <div className="wrapper">
        <ul className="about-list">
          {missionList.map((list) => (
            <li key={list} className="about-item">
              <IonIcon icon={checkmarkCircleOutline} aria-hidden="true" />

              <span className="span">{list}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export function History() {
  return (
    <>
      {" "}
      <p className="tab-text">
        We are a team of quality resources with real innovations and a positive
        customer experience started KommForce to serve our clients with reliable
        test automation solutions.
      </p>
      <div className="wrapper">
        <ul className="about-list">
          {historyList.map((list) => (
            <li key={list} className="about-item">
              <IonIcon icon={checkmarkCircleOutline} aria-hidden="true" />

              <span className="span">{list}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export function WhoWeAre() {
  return (
    <>
      {" "}
      <p className="tab-text">
        At SpunkIT LLC, we firmly believe that creating a more inclusive world
        starts with individual actions. We invite you to be a part of this
        journey by learning, sharing, and advocating for diversity and
        inclusion. Let's build bridges, tear down barriers, and weave a tapestry
        that celebrates the beauty of humanity in all its forms.
      </p>
      <div className="wrapper">
        <ul className="about-list">
          {whoWeAreList.map((list) => (
            <li key={list} className="about-item">
              <IonIcon icon={checkmarkCircleOutline} aria-hidden="true" />

              <span className="span">{list}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
