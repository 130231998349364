import React from "react";
import ArticleRendering from "./ArticleRendering";
import { outsource } from "./artices";

export default function OutsourcingContent() {
  return (
    <section className="section about outsource" id="about" aria-label="about">
      <div className="container">
        <p className="section-subtitle text-center">-Benefits-</p>

        <h2 className="h2 section-title text-center">
          Software Development Outsourcing <br />
          Advantages
        </h2>
        <ul>
          <ArticleRendering article={outsource} />
        </ul>
      </div>
    </section>
  );
}
